const messages = {
    swapShop: 'คุณต้องการสลับไปที่ร้านใหม่ใช่หรือไม่',
    swapShopDespt: 'สินค้าที่เลือกไว้ในร้านก่อนหน้าจะถูกบันทึกไว้ โดยสามารถกลับเข้ามากดสั่งซื้อได้ในภายหลัง',
    sorryForInconvenient: 'ขออภัยในความไม่สะดวก',
    openAt: 'เปิดเวลา',
    shopIsClosedToday: 'ร้านปิดวันนี้',
    checkOrderedItem: 'ตรวจสอบรายการอาหารที่สั่ง',
    tryAgainIn: 'ลองอีกครั้งในเวลา',
    lockUserPIN: 'คุณใส่รหัสผ่านผิดติดต่อกันเกิน 5 ครั้ง',
    lockUserPINDespt: 'ลองใหม่ในอีก 5 นาที',
    inValidPIN: 'รหัสผ่านไม่ถูกต้อง',
    enterPasswordToAccessMenu: "กรอกรหัสผ่านเข้าสู่เมนู",
    customersCanGetPasswordFromStaff: "ลูกค้าสามารถรับรหัสผ่านได้ที่พนักงาน",
    tableNotAvailable: "โต๊ะไม่พร้อมใช้งาน",
    contactStaffToOpenTable: "ติดต่อพนักงานเพื่อทำการเปิดโต๊ะ",
    outOfRestaurantRange: "ไม่ได้อยู่ในรัศมีร้าน",
    phoneIsMoreThan100MetersAwayFromRestaurant: "มือถืออยู่ห่างจากร้านเกินระยะ 100 เมตร",
    restaurantIsCurrentlyClosed: "ร้านปิดอยู่ ณ ขณะนี้",
    restaurantOpenHours: "ร้านเปิดให้บริการ",
    packingSize: 'ขนาดบรรจุ',
    shippingDelivery: 'กำลังจัดส่ง',
    waitForPaymentDelivery: 'รอชำระเงิน',
    readyForPcikup: 'สินค้าพร้อมรับ',
    noNotifyNow: 'ไม่มีรายการแจ้งเตือนในตอนนี้',
    noNotifyNowDespt: 'หากเริ่มใช้บริการแล้ว รายการแจ้งเตือนของคุณจะปรากฎที่นี่',
    newAddressDespt: "เพื่อง่ายต่อการจัดส่ง กรุณาปักหมุดปลายทางการจัดส่งให้ถูกต้องและอนุญาติการเข้าถึงตำแหน่งที่ตั้ง",
    deleteAddressSuccess: 'ลบที่อยู่สำเร็จ',
    createProfileSuccess: "สร้างโปรไล์สำเร็จ",
    ex: 'เช่น',
    receiverName: 'ชื่อผู้รับ',
    editAddress: 'แก้ไขที่อยู่',
    nameInputInvalid: 'ชื่อไม่ถูกต้อง',
    openInGoogleMap: 'เปิดใน Google Map',
    pleaseSelectPaymentMethod: 'กรุณาเลือกสินค้าที่ต้องการชำระเงิน',
    noDeliveryAddress: 'ไม่พบที่อยู่จัดส่ง',
    noDeliveryAddressDespt: 'กรุณาเพิ่มข้อมูลที่อยู่จัดส่ง',
    deliveryType: 'จัดส่ง',
    pickupType: 'รับที่ร้าน',
    shopIsClose: 'ร้านปิดให้บริการอยู่ ณ ขณะนี้',
    openingHoursDespt: 'เวลาเปิดทำการ',
    contactShop: 'ติดต่อร้านค้า',
    pickupAtShop: 'มารับที่ร้าน',
    thanksForOrderingDespt: 'ขอบคุณสำหรับการสั่งซื้อ<br>แล้วพบกันใหม่นะคะ ',
    deliveredSuccess: 'จัดส่งสำเร็จ',
    expireIn: 'หมดเวลาใน',
    waitForPayment: 'รอการชำระเงิน',
    waitForPaymentDespt: 'กรุณารอการยืนยันจากระบบชำระเงิน <br>หากคุณยังไม่ได้ทำรายการชำระเงิน กรุณาชำระเงินในระยะเวลาที่กำหนด<br>เพื่อหลีกเลี่ยงการยกเลิกคำสั่งซื้อ',
    newOrderIn: 'ออเดอร์ใหม่',
    inputRequestDetails: 'ระบุรายละเอียดคำขอ',
    notification: 'การแจ้งเตือน',
    noticeIfOrderReady: 'เราจะแจ้งให้ทราบเมื่อออเดอร์พร้อมเข้ารับ',
    orderIsReady: 'ลูกค้าสามารถมาที่ร้านและติดต่อพนักงานเพื่อรับสินค้า',
    thanksForOrdering: 'ขอบคุณที่ใช้บริการ',
    toPay: 'ที่ต้องชำระ',
    letOrder: 'เข้าสู่หน้าเมนู และ เริ่มการสั่งซื้อสินค้าเลย',
    noTransaction: 'ไม่พบประวัติการสั่งซื้อ',
    menulists: 'รายการอาหาร',
    editPersonalDetails: 'แก้ไขข้อมูลส่วนตัว',
    trackOrder: 'ติดตามคำสั่งซื้อ',
    myAccount: 'บัญชีของฉัน',
    cart: 'ตะกร้าสินค้า',
    helpCenter: 'การช่วยเหลือ',
    allPurchaseHistory: 'ประวัติการสั่งซื้อ',
    purchaseHistory: 'ประวัติการสั่งซื้อ',
    personalInfo: 'ข้อมูลส่วนตัว',
    cutlery: 'รับช้อนส้อม',
    km: 'กม.',
    totalAmountSum: 'ยอดรวมสินค้า',
    selectedItemList: 'รายการที่เลือก',
    iAm: 'คุณ',
    orderedBy: 'สั่งโดย',
    shareQRtoFriend: 'แชร์ QR ให้เพื่อนสั่งอาหาร',
    share: 'แชร์',
    hasItemInBasket: 'มีสินค้าในตะกร้า',
    hasItemInBasketDespt: 'อย่าลืมส่งเข้าครัวด้วยนะคะ',
    updatedAtQR: 'อัพเดทล่าสุด',
    orderSent: 'ส่งรายการเข้าครัวแล้ว',
    orderSentDespt: 'ร้านกำลังทำอาหารให้คุณ',
    checkGramdTotalIn: 'สามารถตรวจสอบยอดสุทธิได้ที่หน้า',
    vatNotInclude: 'ราคานี้ยังไม่รวมภาษี ค่าบริการ และส่วนลด (ถ้ามี)',
    accept: 'เข้าใจแล้ว',
    termOfService: 'เงื่อนไขการใช้บริการ',
    onPending: 'กำลังดำเนินการ',
    OnSuccessOrder: 'สำเร็จ',
    prepareingOrder: 'กำลังจัดเตรียมออเดอร์',
    deliveringOrder: 'คนขับกำลังนำสินค้าไปส่ง',
    pickupOrderReady: 'สินค้าพร้อมรับ',
    pickedupOrder: 'รับสินค้าแล้ว',
    deliveredOrder: 'ส่งสินค้าเสร็จเรียบร้อย',
    shopReceivedOrder: 'ร้านรับออเดอร์',
    orderId: 'หมายเลขออเดอร์',
    orderDetails: 'รายละเอียดคำสั่งซื้อ',
    deliveryFee: 'ค่าส่ง',
    searchDriver: 'กำลังค้นหาคนขับ',
    driverFrom: 'คนขับจาก',
    change: 'เปลี่ยน',
    deliveryAddress: 'ที่อยู่จัดส่ง',
    location: 'ตำแหน่งจัดส่ง',
    addressName: 'ชื่อที่อยู่',
    addressNameDespt: 'เช่น ชื่อคอนโด, ชื่อหมู่บ้าน',
    addressDescription: 'รายละเอียดที่อยู่',
    addressDescriptionDespt: 'เช่น หมายเลขตึก, ซอยที่อยู่',
    noteToDriver: 'ข้อความถึงคนขับ',
    noteToDriverDespt: 'เช่น วางไว้ที่โต๊ะหน้าทางเข้า',
    inputAddressForm: 'กรอกข้อมูลที่อยู่จัดส่ง',
    plsPinAddress: 'กรุณาปักหมุดที่อยู่',
    pinLocation: 'ปักหมุดที่อยู่สำหรับจัดส่ง',
    createNewAddress: 'สร้างที่อยู่ใหม่',
    savedAddress: 'ที่อยู่ทั้งหมด',
    phoneNumber: 'เบอร์มือถือ',
    noRequired: 'ไม่บังคับ',
    resendCode: 'ส่งรหัสอีกครั้ง',
    didNotGetCode: 'ไม่ได้รับรหัส?',
    resendIn: 'ส่งรหัสอีกครั้งในอีก',
    next: 'ถัดไป',
    createAccount: 'สร้างบัญชีผู้ใช้',
    createAccountDespt: 'กรอกฟอร์มสำหรับสร้างบัญชีเพื่อดำเนินการต่อ',
    sendCodeViaSMS: 'ส่งรหัสมาที่ SMS',
    inputOTP: 'กรอกรหัส OTP',
    inputOTPDespt: 'โปรดกรอกเลข 6 หลักจาก SMS เบอร์',
    termAndPolicy: 'ข้อกำหนดและนโยบาย',
    termAndPolicy1: 'การใช้บริการของเรา หมายความว่าคุณยอมรับ',
    termAndPolicy2: 'ของเรา คลิกเพื่ออ่านข้อกำหนดและนโยบายฉบับเต็ม',
    loginWithPhoneNumber: 'เข้าสู่ระบบด้วยเบอร์มือถือ',
    loginWithPhoneNumberDespt: 'กรอกเบอร์มือถือเพื่อรับรหัส OTP สำหรับยืนยันตัวตน',
    backToOrder: 'กลับไปสั่งอาหาร',
    PaymentMethod: 'วิธีการชำระเงิน',
    paymentAmount: 'จำนวนเงินที่ชำระ',
    paymentConfirmation: 'ยืนยันการชำระเงิน',
    cancelPayment: 'ยกเลิกการชำระเงิน',
    paidSuccess: 'ชำระเงินเรียบร้อย',
    haveNotPurchased: 'ยังไม่ชำระเงิน',
    haveNotPurchased2: 'หากชำระเงินไม่สำเร็จ กรุณาติดต่อพนักงาน',
    canNotOrderItem: 'ไม่สามารถสั่งอาหารได้ระหว่างการชำระเงิน',
    checkPaymentStatus: 'ตรวจสอบสถานะ',
    pendingPayment: 'อยู่ระหว่างดำเนินการชำระเงิน',
    askForReceipt: 'หากต้องการใบเสร็จ กรุณาติดต่อพนักงาน',
    paymentSuccess: 'ชำระเงินสำเร็จ',
    summaryPayment: 'สรุปคำสั่งซื้อ',
    numberOfGuest: 'จำนวนลูกค้า',
    closeTab: 'ปิด',
    addCredit: 'เพิ่มบัตรเครดิต',
    QRPayment: 'ชำระด้วย QR',
    cardPayment: 'ชำระด้วยบัตร',
    payWithStaff: 'ชำระที่เคาท์เตอร์',
    selectPaymentMethod: 'เลือกวิธีการชำระเงิน',
    checkOut: 'ชำระเงิน',
    startOrderBeforeCallStaff: 'กรุณาสั่งอาหารก่อนทำการเรียกพนักงาน',
    basket: 'ตะกร้า',
    spoon: 'ขอช้อนส้อม',
    knife: 'ขอมีด',
    chopstick: 'ขอตะเกียบ',
    cup: 'ขอถ้วย',
    plate: 'ขอจาน',
    glass: 'ขอแก้ว',
    payBill: 'เรียกชำระเงิน',
    viewAll: 'ดูทั้งหมด',
    recommendMenu: 'เมนูแนะนำ',
    OnSuccess: 'เรียบร้อย',
    OnPreparing: 'กำลังทำ',
    messageSent: 'กรุณารอพนักงานสักครู่',
    outOfStock: 'สินค้าหมด',
    QRCode: 'คิวอาร์ โค้ด',
    GenerateQRCode: 'สร้างคิวอาร์โค้ด',
    ForQROrder: 'สำหรับสั่งอาหาร และรอรับบริการภายในร้านค้า',
    QROrderDescription: 'คุณสามารถสร้างคิวอาร์โค้ด สำหรับให้ลูกค้าสแกนสั่งอาหาร และรอรับบริการภายในร้านค้า',
    SelectTable: 'เลือกโต๊ะ และระบุจำนวนลูกค้า',
    numberOfCustomer: 'จำนวนลูกค้า (คน)',
    numberOfCustomerDescription: 'ระบุจำนวนลูกค้าอย่างน้อย 1 คน',
    currentUser: 'บัญชีผู้ใช้',
    guest: 'ท่าน',
    orderItem: 'อาหารที่สั่ง',
    orderItemNav: 'อาหารที่สั่ง',
    callStaff: 'เรียกพนักงาน',
    callStaffDialog: 'ลูกค้าต้องการความช่วยเหลือด้านใด?',
    cutlery: 'ขออุปกรณ์',
    seasoning: 'ขอเครื่องปรุง',
    refillDrink: 'เติมเครื่องดื่ม',
    startOrder: 'เริ่มคำสั่งซื้อ',
    other: 'อื่น ๆ',
    tableNumber: 'หมายเลขโต๊ะ',
    menuList: 'หน้าหลัก',
    selectedItem: 'สั่งอาหาร',
    selectedItemNav: 'สั่งอาหาร',
    checkOrderBeforeConfirm: 'โปรดตรวจสอบรายการอาหาร',
    checkOrderBeforeConfirm2: 'และยอดรวมของคุณก่อนสั่ง',
    foodCategory: 'หมวดหมู่อาหาร',
    searchByName: 'ค้นหาสินค้าโดยชื่อ...',
    noPreduct: 'ไม่มีรายการสินค้า',
    noSelectedProduct: 'ยังไม่มีรายการที่คุณเลือก',
    noSelectedProductDespt: 'หากเลือกสินค้าแล้ว จะแสดงรายการที่คุณเลือกในหน้านี้',
    backToMenu: 'ไปที่เมนู',
    removeSelected: 'คุณยืนยันที่จะลบรายการที่เลือก?',
    confirmOrder: 'สั่งอาหาร',
    endOfTransection: 'สิ้นสุดการทำรายการ',
    endOfTransectionDialog: 'กรณีเกิดปัญหาการใช้งาน กรุณาติดต่อพนักงาน',
    contactOurStaff2: 'กรณีเกิดปัญหาการใช้งาน กรุณาติดต่อพนักงาน',
    note: 'โน๊ต',
    orderTime: 'เวลาสั่งซื้อ',
    orderMoreFood: 'กลับสู่เมนูหลัก',
    orderPreparing: 'ทางร้านกำลังเตรียมอาหารของคุณ',
    orderPreparing2: 'โปรดรอสักครู่ อาหารจะพร้อมเสิร์ฟเร็วๆ นี้',
    sendingOrder: 'กำลังส่งออเดอร์เข้าครัว...',
    login: 'เข้าสู่ระบบ',
    loginWithGmail: 'เข้าสู่ระบบด้วย Gmail',
    forgetPassword: 'ลืมรหัสผ่าน',
    or: 'หรือ',
    loginAsStaff: 'เข้าสู่ระบบโดยรหัสพนักงาน',
    billOrder: 'ใบสั่งอาหาร',
    table: 'โต๊ะ',
    billOrderDialog: 'สแกน QR Code เพื่อดูเมนู และสั่งอาหาร',
    billOrderDialog2: 'หากท่านติดปัญหาการใช้งานโปรดแจ้งพนักงาน',
    selectTableAleart: 'กรุณาเลือกโต๊ะก่อนสร้างคิวอาร์โค้ด',
    emptyCartDialog: 'ยังไม่มีการทำรายการ',
    emptyCartDialogDespt: 'หากยืนยันการสั่งสินค้าแล้ว จะแสดงรายการที่คุณสั่งในหน้านี้',
    served: 'เสิร์ฟแล้ว',
    contactOurStaff: 'ติดต่อพนักงานของเรา',
    addToCart: 'หยิบใส่ตะกร้า',
    noteToShop: 'ข้อความถึงร้านค้า',
    addYourRequest: 'เพิ่มข้อความถึงร้านค้า',
    reporttxt: 'รายงาน',
    general: 'ทั่วไป',
    shopname: 'ชื่อร้าน',
    branchName: 'สาขา',
    businessType: 'ประเภทธุรกิจ',
    taxID: 'เลขประจำตัวผู้เสียภาษี',
    vat: 'ภาษีมูลค่าเพิ่ม',
    exclusive: 'รวม VAT',
    inclusive: 'ไม่รวม VAT',
    serviceCharge: 'ค่าบริการ',
    address: 'ที่อยู่',
    address1: 'ที่อยู่บรรทัดที่ 1',
    address2: 'ที่อยู่บรรทัดที่ 2',
    tel: 'เบอร์โทรศัพท์',
    shopopen: 'เวลาเปิด-ปิดร้าน',
    open: 'เปิด',
    closed: 'ปิด',
    languagetxt: 'ภาษา',
    language: 'ภาษา และโซนเวลา',
    languageTH: 'ไทย',
    languageEN: 'อังกฤษ',
    setting: 'การตั้งค่า',
    shop: 'ร้านค้า',
    timezone: 'โซนเวลา',
    deliveryProviders: 'รายชื่อผู้ให้บริการเดลิเวอรี่',
    adddeliveryProviders: 'เพิ่มผู้ให้บริการ',
    lastUpdate: 'วันที่อัพเดต',
    logo: 'โลโก้',
    businessType1: 'ร้านค้าทั่วไป',
    businessType2: 'ร้านอาหาร',
    businessType3: 'โฮสเทล',
    updateData: 'อัพเดทข้อมูล',
    submit: 'ตกลง',
    cancel: 'ยกเลิก',
    normal: 'ปกติ',
    loading: 'กำลังโหลด...',
    editing: 'กำลังแก้ไข',
    confirmUpdate: 'คุณต้องการแก้ไขข้อมูลหรือไม่',
    selectedShoptype: 'เลือกประเภทร้าน...',
    selectshop: 'เลือกสาขา',
    save: 'บันทึก',
    signOut: 'ออกจากระบบ',
    unit: 'หน่วยบรรจุ',
    category: 'กลุ่มสินค้า',
    product: 'สินค้า',
    export: 'ส่งออกไฟล์',
    exportKBank: 'ส่งออกไฟล์ K-Bank',
    date: 'วันที่',
    to: 'ถึง',
    status: 'สถานะ',
    vatAmount: 'คิดเป็นมูลค่าภาษี',
    netTotalNonVAT: 'สินค้าไม่มีภาษี',
    receiptNumber: 'เลขที่ใบกำกับ',
    grandTotal: 'จำนวนเงิน',
    netAmountVATTotal: 'มูลค่าก่อน VAT',
    netTotalIncVAT: 'สินค้ามีภาษี',
    proofOfPayment: 'หลักฐานการชำระเงิน',
    paymentType: 'ประเภทการชำระ',
    paymentType0: 'เงินสด',
    paymentType1: 'บัตรเครดิต',
    paymentType2: 'คูปอง',
    paymentType3: 'Quickpay',
    paymentType4: 'โอน(พร้อมเพย์)',
    paymentType5: 'Alipay',
    paymentType6: 'มัดจำ',
    paymentType7: 'Delivery',
    paymentType8: 'Online',
    paymentType9: 'Line Pay',
    paymentType10: 'อื่นๆ',
    paymentType12: 'KBank',
    payment: 'การชำระเงิน',
    paid: 'มูลค่าการชำระ',
    sentSuccessfully: 'ส่งแล้ว',
    pluCode: 'รหัสสินค้า',
    productName: 'ชื่อสินค้า',
    quantity: 'จำนวน',
    priceUnit: ' ราคาขาย/หน่วย',
    discount: 'ส่วนลด',
    timeOfOrder: 'เวลาที่สั่งซื้อ',
    orderDate: 'สั่งซื้อเมื่อ',
    preparing: 'เวลาเตรียมสินค้า',
    paymentDetail: 'รายละเอียดการขาย',
    orderNumber: 'หมายเลขคำสั่งซื้อ',
    delivered: 'กำหนดส่ง',
    deliveredDate: 'เวลาส่ง',
    baht: 'บาท',
    profit: 'กำไร',
    sales: 'ยอดขาย',
    billAmount: 'จำนวนบิล',
    total: 'ทั้งหมด',
    bill: 'บิล',
    discountAmount: 'ส่วนลดท้ายบิล',
    netAmount: 'ยอดสุทธิ',
    averageBill: 'เฉลี่ย/บิล',
    voidbillTotal: 'มูลค่าการยกเลิก',
    salesByDaily: 'ยอดขายรายวัน',
    salesByMonth: 'ยอดขายรายเดือน',
    bestSellers: 'สินค้าขายดี',
    bestSellerstype: 'ประเภทสินค้าขายดี',
    salesBydate: 'ยอดขายแยกตามช่วงเวลา',
    number: 'ลำดับ',
    categoryName: 'ชื่อประเภท',
    newOrder: 'ส่งรายการ',
    reject: 'ปฏิเสธ',
    rejectTime: 'เวลาที่ปฏิเสธ',
    inProgressTime: 'เวลาเตรียมสินค้า',
    cancelTime: 'เวลาที่ยกเลิก',
    cause: 'เหตุผลที่ยกเลิก',
    preparingToShip: 'กำลังเตรียมสินค้า',
    totalAmount: 'ยอดสุทธิ',
    order: 'คำสั่งซื้อ',
    deposit: 'มัดจำ',
    billSave: 'พักบิล',
    ReceivingOrder: 'เปิดโต๊ะ/กำลังรับออเดอร์',
    orderNo: 'เลขที่รายการ',
    remark: 'หมายเหตุท้ายบิล',
    receiptStatus: 'สถานะ',
    back: 'กลับ',
    detail: 'รายละเอียด',
    countSales: 'ขายได้จำนวน',
    onhandQty: 'คงเหลือ',
    stdCost: 'ราคาทุน',
    employees: 'พนักงาน',
    employeesname: 'ชื่อพนักงาน',
    salesTotal: 'ยอดขายทั้งหมด',
    totalBill: 'บิลทั้งหมด',
    billActive: 'บิลที่ขาย',
    billVoice: 'บิลยกเลิก',
    billDeposit: 'บิลมัดจำ',
    discountItem: 'ส่วนลดในรายการ',
    billDiscountAmount: 'ส่วนลดท้ายบิล',
    billDiscount: 'บิลที่ทำส่วนลด',
    payTotal: 'ชำระรวม',
    deliveryProvider: 'ประเภท Delivery',
    deliveryDetail: 'รายละเอียดรายการ Delivery',
    customDetail: 'รายละเอียดรายการจ่ายเงินอื่นๆ',
    customPaymentName: 'ประเถทรายการ',
    firstInvoiceNO: 'เลขที่บิลเริ่มต้น',
    lastInvoiceNO: 'เลขที่บิลสุดท้าย',
    roundNo: 'รอบการขายที่',
    closeAt: 'เวลาปิดรอบการขาย',
    closeBy: 'ปิดรอบขายโดย',
    totalCashSales: 'ยอดขายด้วยเงินสด',
    initialChange: 'เงินทอนเริ่มต้น',
    totalCashIn: 'เงินเข้า',
    totalCashOut: 'เงินออก',
    actualInDrawer: 'จำนวนเงินที่นับได้ในลิ้นชัก',
    expectedInDrawer: 'จำนวนเงินที่ควรมีในลิ้นชัก',
    difference: 'ส่วนต่าง',
    voidBillDetail: 'รายละเอียดบิลยกเลิกการขาย',
    cancelBy: 'ยกเลิกโดย',
    cancelDate: 'ยกเลิกเวลา',
    searchByEmployees: 'ค้นหาพนักงาน...',
    search: 'ค้นหา',
    timeIn: 'เวลาเข้างาน',
    timeOut: 'เวลาออกงาน',
    time: 'ชั่วโมงการทำงาน',
    hours: 'ชั่วโมง',
    seconds: 'วินาที',
    netTotal: 'ยอดเงินสุทธิ',
    reason: 'หมายเหตุ',
    searchByProduct: 'ค้นหาจากชื่อสินค้า ...',
    sortBy: 'เรียงโดย',
    last: 'ล่าสุด',
    price: 'ราคาสินค้า',
    sortByNumMin: 'ราคาน้อยไปมาก',
    sortByNumMax: 'ราคามากไปน้อย',
    documentNo: 'เลขที่เอกสาร',
    transactionType: 'ประเภท',
    updatedBy: 'ปรับปรุงโดย',
    transactionType1: 'ขายออก',
    transactionType2: 'รับคืนจากลูกค้า',
    transactionType3: 'รับสินค้าเข้า',
    transactionType4: 'จ่ายสินค้าออก',
    transactionType5: 'ปรับปรุงเพิ่ม',
    transactionType6: 'ปรับปรุงลด',
    transactionType7: 'แลกด้วยแต้ม',
    transactionType8: 'ลบ-รับเข้า',
    transactionType9: 'ลบ-จ่ายออก',
    transactionType10: 'แก้ไข-รับเข้า',
    transactionType11: 'แก้ไข-จ่ายออก',
    billNo: 'เลขที่ในบิลซื้อ',
    docTotal: 'ยอดรวม',
    customer: 'ผู้จำหน่าย',
    username: 'ชื่อผู้ใช้',
    dateOfBill: 'วันที่ซื้อในบิล',
    selectDate: 'เลือกวันที่...',
    dialogSelectCategory: 'กรุณาเลือกหมวดหมู่การค้นหา',
    dialogUpdateDocumentSuccess: 'แก้ไขเอกสารเรียบร้อยแล้ว',
    dialogfound: 'กรอกข้อมูลไม่ถูกต้อง',
    isVatIncluded: 'สินค้ารวมภาษีแล้ว',
    vatIccludes: 'คิดภาษีมูลค่าเพิ่ม',
    confirmStock: 'ยืนยันการซื้อ',
    confirm: 'ยืนยัน',
    deleteDoc: 'ลบเอกสาร',
    confirmUpdateDocModal: 'คุณต้องการบันทึกการแก้ไขเอกสารหรือไม่ ?',
    confirmSaveDoc: 'ยืนยันการบันทึกเอกสาร',
    confirmDeleteDoc: 'ยืนยันการลบเอกสาร',
    confirmDeleteModal: 'คุณต้องการลบเอกสารหรือไม่',
    dialogSaveDocumentSuccess: 'สร้างเอกสารรับสินค้าเข้าเรียบร้อยแล้ว',
    createStockIn: 'สร้างเอกสารรับสินค้าเข้า',
    confirmSaveDocModal: 'คุณต้องการบันทึกเอกสารรับเข้าหรือไม่ ?',
    selectShopPlease: 'กรุณาเลือกร้านค้า',
    selectProductPlease: 'กรุณาเลือกสินค้า',
    duplicateProduct: 'คุณเลือกรายการสินค้านี้แล้ว',
    productInvilid: 'รายการสินค้าที่คุณเลือกไม่ถูกต้อง กรุณาติดต่อผู้ดูแลระบบ',
    confirmStockOut: 'ยืนยันการจ่ายออก',
    dialogSaveDocumentOutSuccess: 'สร้างเอกสารจ่ายสินค้าออกเรียบร้อยแล้ว',
    confirmSaveDocStockOutModal: 'คุณต้องการบันทึกเอกสารจ่ายออกหรือไม่ ?',
    adjustDocType: 'ประเภทเอกสาร',
    adjustDocType1: 'ปรับปรุงเพิ่ม',
    adjustDocType2: 'ปรับปรุงลด',
    beforeAdjust: 'ก่อนปรับปรุง',
    afterAdjust: 'หลังปรับปรุง',
    checkCount: 'ยอดตรวจนับ',
    adjust: 'ปรับปรุง',
    createAdjustStock: 'สร้างเอกสารปรับปรุงสินค้า',
    confirmAdjust: 'ยืนยันการปรับปรุง',
    searhBy: 'ค้นหาสินค้าโดย',
    createDoucument: 'สร้างเอกสาร',
    transferType: 'ประเภทเอกสาร',
    transferType1: 'เอกสารรับเข้า',
    transferType2: 'เอกสารจ่ายออก',
    new: 'ใหม่',
    finish: 'สำเร็จ',
    refDocument: 'อ้างอิงจากเอกสาร',
    statusDoc: 'สถานะเอกสาร',
    dialogSaveTransferDocumentSuccess:
        'สร้างเอกสารจ่ายสินค้าระหว่างสาขาเรียบร้อยแล้ว',
    destinationShop: 'ร้านค้าปลายทาง',
    destinationShopBranch: 'สาขาปลายทาง',
    sourceBranch: 'สาขาต้นทาง',
    sourceShop: 'ร้านค้าต้นทาง',
    sourceAddress: 'ที่อยู่สาขาต้นทาง',
    destinationAddress: 'ที่อยู่สาขาปลายทาง',
    saveTransfer: 'บันทึกเอกสารการโอน',
    confirmSaveTransfer: 'คุณต้องการบันทึกการโอนสินค้าระหว่างสาขาหรือไม่',
    savingDoc: 'กำลังบันทึกเอกสาร',
    selectdestinationPlease: 'กรุณาเลือกสาขาปลายทาง',
    validatedestinationproduct: 'สาขาปลายทางไม่มีสินค้า',
    validateProduct: 'กรุณาเพิ่มสินค้า',
    created_by: 'โดย',
    receive: 'รับ',
    updateDoc: 'แก้ไขเอกสาร',
    cancelDoc: 'ยกเลิกเอกสาร',
    confirmCancelDoc: 'คุณต้องการยกเลิกเอกสารหรือไม่',
    cancelingDoc: 'กำลังยกเลิกเอกสาร',
    updatingDoc: 'กำลังแก้ไขเอกสาร',
    updateDocFail: 'ไม่สามารถแก้ไขเอกสารได้',
    cancelDocSuccess: 'ยกเลิกเอกสารสำเร็จ',
    cancelDocFail: 'ไม่สามารถยกเลิกเอกสารได้',
    refDocumentTransfer: 'เลขเอกสารอ้างอิงจากต้นทาง',
    comfrimTransferIn: 'ยืนยันการรับสินค้า',
    dilogcomfrimTransferIn: 'คุณต้องการยืนยันการรับสินค้าหรือไม่',
    transferInDetail: 'รายละเอียดสินค้ารับเข้า',
    totalNet: 'รวมมูลค่าสินค้า',
    createStockOut: 'สร้างเอกสารจ่ายสินค้าออก',
    favorite: 'สินค้า-นิยม',
    isOnScreen: 'แสดงบนหน้าขาย',
    negotiatePrice: 'ราคาด่วน', //
    productTypeP: 'สินค้าทั่วไป',
    productTypeBOM: 'สินค้าประกอบ (BOM)',
    productTypeSN: 'สินค้ามี Serial',
    productTypeSV: 'สินค้าบริการ (สินค้าไม่มีสต๊อก)',
    productPLU: 'ขนาดบรรจุอื่นๆ',
    productBOM: 'ส่วนประกอบ',
    productSN: 'หมายเลขผลิตภัณฑ์',
    morePrice: 'ราคาขายเพิ่มเติม',
    cost: 'ต้นทุน',
    quantityPrice: 'จำนวน/ราคาขาย',
    exceedUc: 'ราคาขาย',
    changeImg: 'เปลี่ยนรูปภาพ',
    addProduct: 'เพิ่มสินค้า',
    selectImg: 'เลือกรูปภาพ',
    deleteImg: 'ลบรูปภาพ',
    selectImgFromPC: 'เลือกรูปภาพจากเครื่อง',
    confirmSaveImgDialog: 'คุณต้องการบันทึกสินค้าหรือไม่',
    confirmSaveImg: 'ยืนยันการบันทึกสินค้า',
    addPrice: 'เพิ่มราคาขาย',
    editPrice: 'แก้ไขราคาขาย',
    dialigDeleteProduct: 'คุณต้องการลบสินค้าหรือไม่',
    confirmDeleteProduct: 'ยืนยันการลบสินค้า',
    productType: 'ประเภทสินค้า',
    countProduct: 'สินค้าทั้งหมด',
    addUnit: 'เพิ่มหน่วยบรรจุ',
    editUnit: 'แก้ไขหน่วยบรรจุ',
    active: 'ใช้งาน',
    inactive: 'ไม่ใช้งาน',
    confirmSaveUnitDialog: 'คุณต้องการบันทึกหน่วยสินค้าหรือไม่',
    confirmSaveUnit: 'ยืนยันการบันทึกหน่วยสินค้า',
    dialigDeleteUnit: 'คุณต้องการลบหน่วยของสินค้าหรือไม่',
    confirmDeletUnit: 'ยืนยันการลบหน่วยสินค้า',
    confirmSaveCategoryDialog: 'คุณต้องการบันทึกกลุ่มสินค้าหรือไม่',
    confirmSaveCategory: 'ยืนยันการบันทึกกลุ่มสินค้าา',
    dialigDeleteCategory: 'ยืนยันการลบกลุ่มสินค้า',
    confirmDeletCategory: 'คุณแน่ใจหรือไม่ว่าคุณต้องการลบกลุ่มสินค้า',
    addCategory: 'เพิ่มกลุ่มสินค้า',
    editCategory: 'แก้ไขกลุ่มสินค้า',
    bgColor: 'สีพื้นหลัง',
    selectbgColor: 'เลือกสีพื้นหลัง',
    validateCategoryname: 'กรุณากรอกชื่อกลุ่มสินค้า',
    sequence: 'ลำดับการแสดงข้อมูล',
    enterProduct: 'กรุณากรอกชื่อสินค้า',
    enterPrice: 'กรุณากรอกราคาสินค้า',
    selectCategory: 'กรุณาเลือกกลุ่มสินค้า',
    selectUnit: 'กรุณาเลือกหน่วยสินค้า',
    confirmSaveProduct: 'คุณต้องการบันทึกสินค้าหรือไม่?',
    confirmSaveProductPrice: 'คุณต้องการบันทึกราคาสินค้าเพิ่มเติมหรือไม่',
    validatepackQty: 'กรุณากรอก จำนวน/ราคาขาย',
    validatepackUc: 'กรุณากรอก ราคาขาย',
    validatePRU: 'ข้อมูลราคาขายเพิ่มเติมนี้มีอยู่ กรุณากรอกใหม่อีกครั้ง',
    createProductSuccess: 'สร้างสินค้าเรียบร้อยแล้ว',
    addSerial: 'เพิ่มหมายเลขผลิตภัณฑ์',
    add: 'เพิ่ม',
    createSerial: 'สร้าง Serial อัตโนมัติ',
    available: 'พร้อมจำหน่าย',
    soldOut: 'จำหน่ายแล้ว',
    deleteSuccess: 'ลบข้อมูลสำเร็จ ',
    enterSerial: 'กรุณากรอก Serial Number',
    confirmSaveSerial: 'คุณต้องการบันทึกหมายเลข Serial number หรือไม่?',
    confirmDelete: 'คุณต้องการลบ Serial number นี้หรือไม่?',
    addProductPLU: 'เพิ่มขนาดบรรจุอื่นๆ',
    img: 'รูปภาพ',
    SKURatio: 'ตัดสต็อก',
    editProductPLU: 'แก้ไขขนาดบรรจุ',
    enterSKURatio: 'กรุณากรอกสต็อกสินค้า',
    comfrimDeletePLU: 'คุณต้องลบขนาดบรรจุหรือไม่?',
    useStepPrice: 'ใช้ราคาขายเพิ่มเติมเมื่อถึงจำนวนที่กำหนดไว้',
    addBOM: 'เพิ่มส่วนประกอบ',
    confirmSaveBOM: 'คุณต้องการบันทึกหรือไม่?',
    user: 'บัญชีร้านค้า',
    userDetail: 'ข้อมูลสมาชิก',
    planDetail: 'รายละเอียดแพ็คเกจ',
    currentPlan: 'แผนบริการ',
    expireDate: 'เริ่มใช้งานเมื่อ',
    startDate: 'เริ่มใช้งานเมื่อ',
    dialogSignOut: 'คุณต้องการออกจากระบบใช่หรือไม่?',
    confirmSignOut: 'กรุณากดตกลงเพื่อออกจากระบบ',
    countMember: 'สมาชิกทั้งหมด',
    addMenber: 'เพิ่มสมาชิก',
    searchMember: 'ค้นหาโดยชื่อ อีเมล์ หรือเบอร์โทรศัพท์',
    dateOfMember: 'เยี่ยมชมครั้งแรก',
    recentVisit: 'เยี่ยมชมครั้งล่าสุด',
    memberName: 'ชื่อสมาชิก',
    email: 'อีเมล',
    paymentTotal: 'ใช้จ่ายสะสม',
    point: 'แต้มสะสม',
    pointtxt: 'คะแนน',
    postalCode: 'รหัสไปรษณีย์',
    saveing: 'กำลังบันทึก . . .',
    edit: 'แก้ไข',
    editMember: 'แก้ไขข้อมูลสมาชิก',
    deleteing: 'กำลังลบ . . .',
    dialogDeleteMember: 'คุณต้องการลบสมาชิก',
    confirmDeleteMember: 'ยืนยันการลบสมาชิก',
    valtdateMember: 'กรุณากรอกข้อมูลให้ครบ',
    createMemberSuccess: 'เพิ่มสมาชิกสำเร็จ !!',
    updateMemberSuccess: 'แก้ไขข้อมูลสมาชิกสำเร็จ !!',
    deleteMemberSuccess: 'ลบข้อมูลสมาชิกสำเร็จ !!',
    onhandQtyMin: 'จำนวนน้อยไปมาก',
    onhandQtyMax: 'จำนวนมากไปน้อย',
    onhandQtySort: 'จำนวนสินค้า',
    showProductAll: 'สินค้าทั้งหมด',
    updateAt: 'ปรับปรุงล่าสุด',
    sortName: 'ชื่อสินค้า',
    stockUnit: 'หน่วยละ',
    selectd: 'เลือก',
    searchProduct: 'ค้นหาสินค้า',
    reset: 'รีเซต',
    selectdProduct: 'เลือกสินค้า',
    information: 'ข้อมูลเบื้องต้น',
    adjustStockList: 'รายการปรับปรุงสต๊อก',
    adjustDetail:
        'เพิ่มสินค้าลงรายการปรับแรุงสต๊อก และตั้งค่ายอดตรวจนับของสินค้าในแต่ละชิ้น',
    transferoutList: 'รายการโอนสินค้าระหว่างสาขา',
    addProductTransfer:
        'เพิ่มสินค้าลงรายการโอนสินค้าระหว่างสาขา และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    transferDetail: 'รายการโอนสินค้าระหว่างสาขา และส่วนลดของสินค้าในแต่ละชิ้น',
    transferinList:
        'รายการสินค้ารับเข้าระหว่างสาขา และรายการส่วนลดของสินค้าในแต่ละชิ้น',
    stockOutList: 'รายการสินค้าจ่ายออก',
    addstockOutList:
        'เพิ่มสินค้าลงรายการจ่ายออก และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    stockinList: 'รายการสินค้ารับเข้า',
    addProductStockin:
        'เพิ่มสินค้าลงรายการรับเข้า และตั้งค่าส่วนลดของสินค้าในแต่ละชิ้น',
    deleteCategory: 'ลบกลุ่มสินค้า',
    item: 'รายการ',
    from: 'จาก',
    service: 'บริการ',
    resource: 'ทรัพยากร',
    bookingNumber: 'หมายเลขการจอง',
    days: 'วัน',
    mounth: 'เดือน',
    weeks: 'สัปดาห์',
    years: 'ปี',
    dashboardBook: 'รายการจอง',
    resourceType: 'ประเภททรัพยากร',
    categoryBooking: 'หมวดหมู่',
    memberDetail: 'ข้อมูลสมาชิก',
    package: 'รายละเอียดแพ็กเกจ ',
    startPackate: 'เริ่มใช้งานเมื่อ',
    endPackate: 'ใช้งานได้ถึง',
    name: 'ชื่อสินค้า',
    productImg: 'ภาพสินค้า',
    fistname: 'ชื่อ',
    lastname: 'สกุล',
    saveMember: 'บันทึกข้อมูล',
    confirmSaveMember: 'คุณต้องการยืนยันการบันทึกข้อมูลใช่หรือไม่',
    yesOrNo: 'ใช่หรือไม่',
    totaValue: 'มูลค่ารวม',
    deleteMember: 'ลบสมาชิก',
    frequently: 'สินค้าที่ซื้อบ่อย',
    recentPurchase: 'การซื้อล่าสุด',
    paymentDescription: 'รายละเอียดการขาย',
    validateInfomation: 'กรุณากรอกข้อมูลที่จำเป็นให้ครบถ้วน',
    validateTel: 'เบอร์มือถือไม่ถูกต้อง',
    validateOTP: 'รหัส OTP ไม่ถูกต้อง',
    validateEmail: 'อีเมล์ไม่ถูกต้อง',
    saveSuccess: 'บันทึกข้อมูลสำเร็จ',
    txtcustomer: 'รายชื่อลูกค้า',
    customerName: 'ชื่อลูกค้า',
    customerCount: 'ลูกค้าทั้งหมด',
    addCustomer: 'เพิ่มลูกค้า',
    permissionManage: 'สิทธิการเข้าถึง',
    mainMenu: 'เมนูหลัก',
    page: 'หน้า',
    show: 'แสดง',
    delete: 'ลบ',
    administratorTools: 'เครื่องมือสำหรับผู้ดูแลระบบ',
    deviceName: 'ชื่ออุปกรณ์',
    lastSyncProduct: 'เวลาใช้งานล่าสุด',
    version: 'เวอร์ชั่น',
    posstatus: 'สถานะการเชื่อมต่อกับระบบ',
    posNumber: ' หมายเลขเครื่อง POS',
    titleSilomconnect: 'ไม่พลาดทุกยอดขายด้วยบริการจาก',
    updateSilom: 'อัปเดตยอดขายรายวันผ่าน LINE ฟรี',
    addfriend: 'เพิ่มเพื่อน',
    enterToReciveService: 'กดรับรหัสสำหรับใช้บริการ',
    verifyCodeToUsed:
        'เพื่อเริ่มต้นใช้งานระบบ คุณสามารถกดรับรหัสเพื่อใช้ในการยืนยันตัวตน',
    verifyfivemin: 'รหัสยืนยันสามารถใช้ได้ภายใน 5 นาที',
    yourVertify: 'รหัสของคุณคือ',
    pressCode: 'กดรับรหัส',
    exCode: 'รหัสหมดอายุภายใน',
    sessionEx: 'เซสชันการเข้าสู่ระบบหมดอายุ กรุณาล็อกอินใหม่อีกครั้ง',
    refresh: 'รีเฟรช',
    selectedFile: 'เลือกไฟล์',
    fileSize: 'ขนาดไฟล์แนะนำ ไม่เกิน 2 MB',
    isBeingToUsed: 'กำลังถูกใช้งาน',
    notInUse: 'ว่างจากการใช้งาน',
    confirmUpdateInformation: 'ยืนยันการอัพเดพข้อมูล',
    memberCount: 'สมาชิกทั้งหมด',
    orderMachine: 'เครื่อง Order',
    textMember: 'รายชื่อสมาชิก',
    manageShop: 'จัดการข้อมูลร้านค้าเบื้องต้น',
    lansetting: 'ตั้งค่าภาษา และโซนเวลาเบื้องต้นสำหรับร้านค้า',
    timezoneSetiing: 'ตั้งค่าโซนเวลาสหรับร้านค้าเบื้องต้น',
    deliveryProviderSetting: 'รายชื่อผู้ให้บริการเดลิเวอรี่ สำหรับร้านค้า',
    toolsSetting: 'จัดการเครื่อง POS สำหรับร้านค้า',
    checkStockPLU: 'สินค้าคงเหลือ < 0',
    searching: 'กำลังค้นหา',
    allProduct: 'สินค้าทั้งหมด',
    foundProductOnhand: 'พบจำนวนคงเหลือในขนาดบรรจุอื่นคงเหลือน้อยกว่า 0',
    confirmAdjustBefor: 'ท่านต้องการให้ระบบปรับปรุงอัตโนมัติหรือไม่',
    requireAdjust: 'ต้องการ',
    imrequireAdjust: 'ไม่ต้องการ',
    islimitItemTodoc: 'เลือกรายการสินค้าได้ไม่เกิน 200 รายการ / 1 เอกสาร',
    barcodePrint: 'พิมพ์บาร์โค้ด',
    printEx: 'ตัวอย่าง & พิมพ์',
    print: 'พิมพ์',
    downloadAndExport: 'ดาวน์โหลดและพิมพ์',
    selectFormat: 'เลือกรูปแบบกระดาษ',
    peperFormat: 'รูปแบบกระดาษ', //รูปแบบกระดาษ
    optional: 'ตัวเลือกเสริม', //Optional
    standardA427: 'มาตรฐาน A4 : 27 รายการ / แผ่น',
    standardA450: 'มาตรฐาน A4 : 52 รายการ / แผ่น',
    standardA460: 'มาตรฐาน A4 : 60 รายการ / แผ่น',
    standardA556: 'มาตรฐาน A4  : 56 รายการ / แผ่น (สติ๊กเกอร์ขนาด A5)',
    standardA550: 'ขนาดกระดาษ A5 : 52 รายการ / แผ่น',
    standardA560: 'ขนาดกระดาษ A5 : 60 รายการ / แผ่น',
    countBarcode: 'จำนวนบาร์โค้ด',
    barcode: 'รหัสบาร์โค้ด',
    setFontsize: 'กำหนดตัวอักษร',
    accessPermissionDes: 'กำหนดสิทธิการเข้าถึงสำหรับผู้ใช้',
    deleteUser: 'ยืนยันการลบผู้ใช้',
    confirmDeleteUser: 'คุณต้องการลบผู้ใช้นี้ หรือไม่?',
    confirmUpdateUserInfo: 'คุณต้องการบันทึกข้อมูลนี้หรือไม่?',
    passAtless: 'รหัสควรมีความยาวอย่างน้อย 6 ตัวอักษร',
    inValidPass: 'รหัสผ่านไม่ถูกต้อง',
    createBy: 'สร้างโดย',
    adjustAutoDes:
        'หากไม่ต้องการให้ระบบปรับปรุงอัตโนมัติ อาจทำให้ยอดจำนวนคงเหลือรวมสินค้าผิดพลาดได้',
    addUser: 'เพิ่มผู้ใช้',
    isMenu: 'แสดงบนเมนูลูกค้า',
    menuSmart: 'จัดการเมนูสินค้า',
    smartSettingTitle: 'ตั้งค่าเมนูสินค้า',
    smartSetting: 'ตั้งค่าเมนูสินค้า สำหรับระบบสมาชิกสะสมแต้ม - CRM ',
    confirmEnable: 'ท่านต้องการเปิดการใช้งานเมนูสินค้าหรือไม่',
    confirmDisEnable: 'ท่านต้องการปิดการใช้งานเมนูสินค้าหรือไม่',
    confirmSelfOrderEnable: 'ท่านต้องการเปิดการใช้งานสั่งซื้อด้วยตนเองหรือไม่',
    confirmSelfOrderDisable: 'ท่านต้องการปิดการใช้งานสั่งซื้อด้วยตนเองหรือไม่',
    enable: 'เปิดใช้งาน',
    disable: 'ปิดใช้งาน',
    usabilityStatus: 'สถานะการใช้งาน',
    manageShopDes: 'ท่านสามารถดูคู่มือการใช้งานได้',
    manageSmartOnProduct:
        'ท่านสามารถ "เพิ่ม/แก้ไข" รายการสินค้าที่ต้องการแสดงบนเมนูลูกค้ได้ที่ หน้า',
    manageSmartOnCategory:
        'ท่านสามารถ "เพิ่ม/แก้ไข" กลุ่มสินที่ต้องการแสดงบนเมนูลูกค้าได้ที่ หน้า',
    turnonSmart: 'และเลือกแสดงบนเมนูลูกค้า',
    desSmartMenu:
        'การตั้งค่าเมนู สำหรับแสดงสินค้าในรูปแบบ e-menu แสดงบน Line  เพื่อให้ลูกค้าเข้าถึงได้ง่ายขึ้น และสามารถสั่งสินค้าด้วยตนเองได้',
    link: 'ที่นี่',
    createTranferOut: 'สร้างเอกสารโอนสินค้าออก',
    roundingValue: 'ปัดเศษ',
    limitItems: 'สามารถเพิ่มสินค้าได้ไม่เกิน 200 รายการ',
    IncomeCategory: 'รายรับ',
    ExpenseCategory: 'รายจ่าย',
    saveBy: 'บันทึกโดย',
    saveDate: 'วันทึกวันที่',
    drawerCate: 'หมวดหมู่',
    openingHours: 'เวลาเปิด-ปิดร้าน',
    shopLocation: 'ที่อยู่ร้าน',
    sunday: 'วันอาทิตย์',
    monday: 'วันจันทร์',
    tuesday: 'วันอังคาร',
    wednesday: 'วันพุธ',
    thursday: 'วันพฤหัสบดี',
    friday: 'วันศุกร์',
    saturday: 'วันเสาร์',
    lat: 'ละติจูด',
    lng: 'ลองจิจูด',
    shopCoverSetting: 'ตั้งค่าหน้าปก',
    shopCoverSettingDes: 'ตั้งค่าภาพหน้าปกร้านค้า เพื่อแสดงผลฝั่งลูกค้า',
    locationSettingDes: 'ระบุตำแหน่งที่ตั้งของร้านค้า',
    updatelocation: 'อัพเดตตำแหน่งที่ตั้งร้านค้า', //Update location
    searchLocation: 'ค้นหาตำแหน่ง', //Search Location
    imaginaryLine: 'ระบุพิกัด', //Imaginary Line
    openingHoursDes: 'ตั้งค่ากำหนดเวลาเปิดปิดร้าน สำหรับร้านค้า',
    paymentDescriptionConfig: 'เลือกประเภทการชำระเงินสำหรับร้านค้า',
    creditCard: 'บัตรเครดิต/เดบิต',
    promptPay: 'โอน (พร้อมเพย์)',
    coupon: 'คูปอง',
    quickpay: ' Krungsri Quick Pay',
    alipay: 'Alipay',
    linepay: 'Rabbit LINE Pay',
    thaidotcom: 'Thai Dot Com Payment',
    dayMY: ' วัน/เดือน/ปี',
    byStockIn: 'ชื้อสินค้า',
    stockOutOther: 'ค่าใช้จ่ายอื่นๆ',
    drawerSales: 'ขายสินค้า',
    loss: 'ขาดทุน',
    sumIncomeExpenses: 'สรุป รายรับ-รายจ่าย สำหรับเดือน', //Summary of income-expenses for the month
    incomeOther: 'รายรับอื่นๆ',
    grossProfit: 'กำไรสุทธิ',
    newUser: 'เพิ่มผู้ใช้',
    userType: 'ประเภทผู้ใช้',
    password: 'รหัสผ่าน',
    confirmPassword: 'ยืนยันรหัสผ่าน',
    ParticipatingProducts: 'เลือกรายการสินค้า',
    descriptionSilomOrder:
        'เลือกรายการสินค้าสำหรับแสดงบน order.silompos.com ซึ่งลูกค้าสามารถทำการสั่งซื้อสินค้าด้วยตนเองได้',
    cashierManagement: 'จัดการแคชเชียร์',
    cashierManageButton: 'จัดการข้อมูล',
    cashierUsernamePassword: 'สร้างชื่อผู้ใช้/รหัสผ่าน',
    cashierCRUDDescription: 'สำหรับเข้าใช้หน้าเว็บ order.silompos.com',
    password: 'รหัสผ่าน',
    placeholderPassword: 'กรอกรหัสผ่านอย่างน้อย 6 ตัวอักษร',
    resetPassword: 'รีเซ็ตรหัสผ่าน',
    oldPassword: 'รหัสผ่านเดิม',
    oldPasswordPlaceholder: 'กรอกรหัสผ่านเดิม',
    newPassword: 'รหัสผ่านใหม่',
    purchaseDetail: 'รายละเอียดการซื้อ',
    uploadImage: 'อัพโหลดรูปภาพ',
    keyword: 'กรอกคำค้นหา',
    firstname: 'ชื่อ',
    lastname: 'นามสกุล',
    province: 'จังหวัด',
    district: 'เขต/อำเภอ',
    subdistrict: 'แขวง/ตำบล',
    addressNumber: 'เลขที่/ถนน/ตึก/อาคาร',
    setAsDefaultAddress: 'เลือกเป็นที่อยู่ตั้งต้น',
    setAsTaxAddress: 'ตั้งเป็นที่อยู่สำหรับออกใบกำกับภาษี',
    setAsShippingAddress: 'ตั้งเป็นที่อยู่สำหรับจัดส่งสินค้า',
    contact: 'ช่องทางติดต่อ',
    setAsDefault: 'ตั้งเป็นค่าเริ่มต้น',
    addressLists: 'รายการที่อยู่',
    default: 'ค่าเริ่มต้น',
    taxAddress: 'ออกใบกำกับภาษี',
    shippingAddress: 'จัดส่งสินค้า',
    addAddress: 'เพิ่มที่อยู่',
    createdAt: 'สร้างเมื่อ',
    updatedAt: 'ปรับปรุงเมื่อ',
    by: 'โดย',
    readyForSale: 'พร้อมขาย',
    sold: 'ขายแล้ว',
    delete: 'ลบ',
    items: 'รายการสินค้า',
    itemDiscount: 'ส่วนลดรายการ',
    failedToSave: 'ไม่สามารถบันทึกสินค้าได้!',
    dateOfBirth: 'วันเกิด',
    menu: 'เมนู',
    dashboardMenu: 'รายงานสรุป',
    transactionMenu: 'ประวัติการขาย',
    currentBillMenu: 'บิลที่เปิดอยู่',
    reportMenu: 'รายงาน',
    orderDeviceMenu: 'เครื่อง Order',
    memberMenu: 'สมาชิก',
    inventoryMenu: 'งานคลังสินค้า',
    productMenu: 'สินค้า',
    selfOrderMenu: 'สั่งซื้อด้วยตนเอง',
    settingMenu: 'การตั้งค่า',
    salesReportByDate: 'ยอดขายสินค้าตามวัน',
    salesReportByBill: 'ยอดขายตามรายละเอียดบิล',
    salesQuantitySummaryByProductReport: 'ยอดขายตามสินค้า',
    quantitySummaryByPLUReport: 'ยอดขายสินค้าตามขนาดบรรจุ',
    nonSellingProduct: 'สินค้าที่ไม่มีการขาย',
    salesSummaryByCashierReport: 'ยอดขายแยกตามพนักงาน',
    salesSummaryByCategoryReport: 'การขายแยกตามกลุ่มสินค้า',
    paymentReport: 'การชำระเงิน',
    salesTaxReport: 'สรุปภาษีขาย',
    drawerReport: 'ปิดรอบการขาย',
    voidBillReport: 'ยกเลิกการขาย',
    timesheetReport: 'ชั่วโมงการทำงาน',
    stockInByListReport: 'รับสินค้าเข้าแสดงรายการ',
    stockOutByListReport: 'จ่ายสินค้าออกแสดงรายการ',
    nonAdjustStockProductReport: 'สินค้าที่ยังไม่ปรับปรุงสต๊อก',
    exportProductReport: 'ส่งออกรายการสินค้า',
    salesSummaryByOrderReport: 'ยอดขายสินค้า',
    salesSummaryProductByOrderReport: 'สินค้าขายได้',
    voidBillByOrderReport: 'ยกเลิกการขายสินค้า',
    inventoryReport: 'สินค้าคงเหลือตาม SKU',
    inventoryReportByPLU: 'สินค้าคงเหลือตามขนาดบรรจุ',
    inventoryReportBySerial: 'สินค้ามี Serial No.',
    stockMovement: 'ความเคลื่อนไหวสินค้า',
    stockInDocument: 'เอกสารรับสินค้าเข้า',
    stockOutDocument: 'เอกสารจ่ายสินค้าออก',
    adjustStockDocument: 'เอกสารปรับปรุงสต๊อก',
    selfOrder: 'ช่องทางการขาย',
    cashier: 'พนักงาน',
    permission: 'สิทธิการเข้าถึง',
    menuSetting: 'ตั้งค่าเมนูสินค้า',
    report: {
        dashboard: 'รายงานสรุป',
        transaction: 'ประวัติการขาย',
        currentbill: 'บิลที่เปิดอยู่',
        daily: 'ยอดขายตามวัน',
        txtdaily: 'รายงานยอดขายสินค้าตามวัน',
        dailyDes: 'รายละเอียดยอดขายสินค้าตามวัน',
        sellbyProduct: 'การขายตามรายละเอียดบิล',
        txtsellbyProduct: 'รายงานยอดขายตามรายละเอียดบิล',
        sku: 'ยอดขายตามสินค้า',
        txtsku: 'รายงานยอดขายตามสินค้า',
        plu: 'ยอดขายสินค้าตามขนาดบรรจุ',
        textPlu: 'รายงานยอดขายสินค้าตามขนาดบรรจุ',
        paymentreport: 'การชำระเงิน',
        txtpaymentreport: 'รายงานการชำระเงิน',
        timesheet: 'ชั่วโมงการทำงาน',
        txtTimesheet: 'รายงานชั่วโมงการทำงาน ',
        unsell: 'สินค้าที่ไม่มีการขาย',
        txtUnsell: 'รายงานสินค้าที่ไม่มีการขาย',
        byCategory: 'ยอดขายตามกลุ่มสินค้า',
        byCashier: 'ยอดขายตามพนักงาน',
        salesTax: 'สรุปภาษีการขาย',
        txtSalesTax: 'รายงานสรุปภาษีขาย',
        voidbill: 'ยกเลิกการขาย',
        txtvoidbill: 'รายงานยกเลิกการขาย',
        closedSale: 'ปิดรอบการขาย',
        txtCloseSale: 'รายงานปิดรอบการขาย',
        salesByOrder: 'ยอดขาย',
        productByOrder: 'ยอดขายตามสินค้า',
        unsalesByOrder: 'ยกเลิกการขาย',
        txtbyCategory: 'รายงานการขายตามกลุ่มสินค้า ',
        txtbyCashier: 'รายงานยอดขายตามพนักงาน',
        txtDeliveryRoport: 'รายงานการชำระเงินตามรายชื่อ Delivery',
        txtCustomReport: 'รายงานการชำระเงินประเภทอื่นๆ',
        txtSalesByOrder: 'รายงานสินค้าขายได้ตามเครื่อง Order',
        txtDailyOrder: 'รายงานยอดขายสินค้าตามเครื่อง Order',
        txtViodOrder: 'รายงานยกเลิกการขายสินค้าตามเครื่อง Order',
        nonadjust: 'สินค้าที่ยังไม่ปรับปรุงสต๊อก',
        txtnonadjust: 'รายงานสินค้าที่ยังไม่ปรับปรุงสต๊อก',
        stockInReport: 'รายงานรับสินค้าเข้าแสดงรายการ',
        stockOutReport: 'รายงานจ่ายสินค้าออกแสดงรายการ',
        stockIn: 'รับสินค้าเข้าแสดงรายการ',
        stockOut: 'จ่ายสินค้าออกแสดงรายการ',
        exportProduct: 'ส่งออกรายการสินค้า',
        txtExportProduct: 'รายงานส่งออกรายการสินค้า',
        cashDrawer: 'จัดการเงินสด',
        txtCashDrawerr: 'รายงานการจัดการเงินสดประจำรอบแบบแสดงรายละเอียด',
    },
    drawer: {
        cashDrawer: 'จัดการเงินสด',
        txtCashDrawerr: 'รายงานการจัดการเงินสดประจำรอบแบบแสดงรายละเอียด',
        cashRevenue: 'รายรับ-รายจ่าย',
        txtcashRevenue: 'รายงานรายรับ-รายจ่าย',
    },
    management: {
        txtManagement: 'การจัดการ',
        user: 'บัญชีร้านค้า',
        shopBranch: 'ร้านค้าและสาขา',
        productManagement: 'การจัดการสินค้า',
        unitManagement: 'หน่วยบรรจุ',
        categorytManagement: 'กลุ่มสินค้า',
        inventory: 'งานคลังสินค้า',
        stockIn: 'รับสินค้าเข้า',
        stockOut: 'จ่ายสินค้าออก',
        adjustStock: 'ปรับปรุงสต๊อก',
        checkStock: 'Check stock',
        stockMovement: 'ความเคลื่อนไหวสินค้า',
        transferStock: 'โอนสินค้าระหว่างสาขา',
        reportByPLU: 'สินค้าคงเหลือตามขนาดบรรจุ',
        reportBySKU: 'สินค้าคงเหลือตาม SKU',
        txtreportBySKU: 'รายงานสินค้าคงเหลือตาม SKU',
        txtreportByPLU: 'รายงานสินค้าคงเหลือตามขนาดบรรจุ',
        stockInDoc: 'เอกสารรับสินค้าเข้า',
        stockOutDoc: 'เอกสารจ่ายสินค้าออก',
        adjustStockDoc: 'เอกสารปรับปรุงสต๊อก',
        transferStockDoc: 'เอกสารโอนสินค้าระหว่างสาขา',
        transferStockInDoc: 'เอกสารรับสินค้าระหว่างสาขา',
        transferStockOutDoc: 'เอกสารโอนสินค้าระหว่างสาขา',
    },
    member: {
        txtmember: 'สมาชิก',
        register: 'ลงทะเบียนร้านค้า (LINE OA)',
        loyaltyPoint: 'คะแนนสะสม',
        storeCredit: 'เครดิตสะสม',
        deal: 'ดีลสินค้า',
        giftVoucher: 'บัตรกำนัล',
        referral: 'แนะนำเพื่อน',
        crm: 'ระบบสมาชิก',
    },
    unableStockDescription:
        'ไม่สามารถแก้ไขสินค้าได้เนื่องจากมีการปรับปรุงสต๊อกภายหลังการสร้างเอกสาร',
    noItem: 'ไม่มีรายการ',
    enterProductName: 'กรอกชื่อสินค้า',
    ByCategory: 'ตามกลุ่มสินค้า',
    ByCategoryDetail: 'ตามรายละเอียดสินค้า',
    maximum: 'สูงสุด',
    selectZone: 'เลือกโซน',
    startTime: 'เวลาเริ่ม',
    endTime: 'หมดเวลาสั่ง',
    "errorMessages": {
        "qrExpired": "คิวอาร์โค้ดหมดอายุ",
        "qrNotFound": "ไม่พบคิวอาร์โค้ด",
        "paymentSuccess": "การชำระเงินสำเร็จ",
        "invalidQrType": "ประเภทคิวอาร์โค้ดไม่ถูกต้อง",
        "tableMoved": "โต๊ะถูกย้าย"
    },
    "errorDescriptions": {
        "qrExpired": "คิวอาร์โค้ดที่คุณใช้หมดอายุแล้ว <br>กรุณาติดต่อพนักงานเพื่อขอความช่วยเหลือ",
        "qrNotFound": "ไม่พบข้อมูลคิวอาร์โค้ดสำหรับการสั่งอาหาร <br>กรุณาติดต่อพนักงาน",
        "paymentSuccess": "ขอบคุณสำหรับการใช้บริการ!",
        "invalidQrType": "กรุณาติดต่อพนักงานเพื่อขอคิวอาร์โค้ดแบบไดนามิก",
        "tableMoved": "กรุณาติดต่อพนักงานเพื่อขอคิวอาร์โค้ดใหม่"
    },
    waitForServe: 'รอเสิร์ฟ',

}

export default messages
